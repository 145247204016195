import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    apis: any;
    app: any;

    constructor(
        private http: HttpClient
    ) {

        this.apis = environment.apis;
        this.app = environment.app;

    }

    stores() {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-store/by-app-uuid/${this.app.uuid}?size=9999`)
            .pipe(map(stores => {
                return stores;
            }));

    }

    categories(storeId) {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-item-category/by-store/${storeId}?size=9999`)
            .pipe(map(categories => {
                return categories;
            }));

    }

    products(catId) {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-item/by-item-category/${catId}?size=9999`)
            .pipe(map(categories => {
                return categories;
            }));

    }


    product(productId) {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-item/${productId}`)
            .pipe(map(product => {
                return product;
            }));

    }


    workplace(workplaceIdentifier) {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-workplace/by-identifier/${workplaceIdentifier}`)
            .pipe(map(workplace => {
                return workplace;
            }));

    }


}
