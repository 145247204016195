import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class BuilderService {

    apis: any;

    constructor(
        private http: HttpClient
    ) {

        this.apis = environment.apis;

    }


    buildersByCat(catId) {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-builder/by-item-category/${catId}?size=9999`)
            .pipe(map(builders => {
                return builders;
            }));

    }




    builder(builderId) {

        return this.http.get<any>(`${this.apis.bludot}/public/ext-builder/${builderId}`)
            .pipe(map(builders => {
                return builders;
            }));

    }

}
